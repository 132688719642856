var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-center","centered":"","title":"Добавить категорию"},on:{"change":_vm.hideModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"res-w-100",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){return cancel()}}},[_vm._v(" Отмена ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"res-mt-2 res-w-100",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Добавить")])])]}}]),model:{value:(_vm.isAddNewUserModalActive),callback:function ($$v) {_vm.isAddNewUserModalActive=$$v},expression:"isAddNewUserModalActive"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название Ru","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название Ru","label-for":"new-merch-title-ru"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-merch-title-ru","placeholder":"Название Ru"},model:{value:(_vm.titleRu),callback:function ($$v) {_vm.titleRu=$$v},expression:"titleRu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название Uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название Uz","label-for":"new-merch-title-uz"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-merch-title-uz","placeholder":"Название Uz"},model:{value:(_vm.titleUz),callback:function ($$v) {_vm.titleUz=$$v},expression:"titleUz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название En","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название En","label-for":"new-merch-title-en"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-merch-title-en","placeholder":"Название En"},model:{value:(_vm.titleEn),callback:function ($$v) {_vm.titleEn=$$v},expression:"titleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Лого","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Лого","label-for":"new-merch-logo"}},[_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-merch-logo","accept":"image/png, image/jpeg, image/tiff","placeholder":"Выберите изображение...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.handleFileUpload($event)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('div',[(_vm.imageRequired === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("Поле Лого поддерживает только файлы в формате .jpg, .png, .tiff")]):_vm._e()]),_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]),_c('span',{staticClass:"sm-size"},[_vm._v("(Поддерживаются файлы в формате .jpg, .png, .tiff) ")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }