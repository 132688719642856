<template>
  <b-modal
    id="modal-center"
    @change="hideModal"
    centered
    v-model="isAddNewUserModalActive"
    title="Добавить категорию"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Название Ru"
              rules="required"
            >
              <b-form-group label="Название Ru" label-for="new-merch-title-ru">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="titleRu"
                  id="new-merch-title-ru"
                  placeholder="Название Ru"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Название Uz"
              rules="required"
            >
              <b-form-group label="Название Uz" label-for="new-merch-title-uz">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="titleUz"
                  id="new-merch-title-uz"
                  placeholder="Название Uz"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Название En"
              rules="required"
            >
              <b-form-group label="Название En" label-for="new-merch-title-en">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="titleEn"
                  id="new-merch-title-en"
                  placeholder="Название En"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Лого"
              rules="required"
            >
              <b-form-group label="Лого" label-for="new-merch-logo">
                <b-form-file
                  v-model="file"
                  @change="handleFileUpload($event)"
                  :state="errors.length > 0 ? false : null"
                  id="new-merch-logo"
                  accept="image/png, image/jpeg, image/tiff"
                  placeholder="Выберите изображение..."
                  drop-placeholder="Drop file here..."
                />
                <div>
                  <small class="text-danger" v-if="imageRequired === 1"
                    >Поле Лого поддерживает только файлы в формате .jpg, .png,
                    .tiff</small
                  >
                </div>
                <div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>

                <span class="sm-size"
                  >(Поддерживаются файлы в формате .jpg, .png, .tiff)
                </span>
                <!-- <b-card-text class="my-1">
                Выбранный файл: <strong>{{ file ? file.name : "" }}</strong>
              </b-card-text> -->
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        type="reset"
        class="res-w-100"
        @click="cancel()"
      >
        Отмена
      </b-button>
      <b-button
        class="res-mt-2 res-w-100"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click.prevent="handleSubmit"
      >
        <span class="text-nowrap">Добавить</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormFile,
  BRow,
  BCol,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BButton,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, mimes } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    BFormFile,
    BRow,
    BCol,
    ToastificationContent,
    BFormTextarea,
    flatPickr,
    BFormRadioGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddNewUserModalActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {},
  data() {
    return {
      required,
      mimes,
      titleRu: null,
      titleUz: null,
      titleEn: null,
      file: null,
      fileUrl: null,
      coords: [],
      imageRequired: 0,
    };
  },
  methods: {
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success && this.imageRequired === 2) {
          axiosIns
            .post("vendor-category/create", {
              titleRu: this.titleRu,
              titleUz: this.titleUz,
              titleEn: this.titleEn,
              logoUrl: this.fileUrl,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Мерчант был успешно создан!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.hideModal();
              this.$emit("refetch-data");
              setTimeout(() => {
                this.resetForm();
              }, 200);
            });
        }
      });
    },
    async handleFileUpload(event) {
      this.file = event.target.files[0];
      if (
        this.file.type != "image/jpg" &&
        this.file.type != "image/jpeg" &&
        this.file.type != "image/png" &&
        this.file.type != "image/tiff"
      ) {
        this.file = "";
        this.imageRequired = 1;
        return false;
      }
      this.imageRequired = 2;
      const formData = new FormData();
      formData.append("logo", this.file);
      const response = await axiosIns.post("image/upload/category", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.fileUrl = response.data.data.imageUrl;
    },
    hideModal() {
      this.$emit("hideModal");
    },
    resetForm() {
      this.titleRu = null;
      this.titleUz = null;
      this.titleEn = null;
      this.file = null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
